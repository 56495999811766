<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg12.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <img
            class="n-logo"
            style="max-height: 150px"
            src="img/now-logo.png"
            alt=""
          />

          <h1 class="title">Globalni projekat</h1>
          <div class="text-center">
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-google-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Šta želimo?</h2>
            <h5 class="description">
              Šta je direktna demokratija? Koji su njeni instrumenti, koje su
              procedure i gdje se praktikuje direktna demokratija? A kad smo već
              kod demokratije na transnacionalnom nivou, kako društva u Evropi i
              svijetu mogu postati demokratičnija?
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/bg13.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  Osnovni izrazi direktne demokratije - građanska inicijativa,
                  obavezni referendum i plebiscit!
                  <br />
                  <br />
                  <small>-Democracy International</small>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div
                class="image-container"
                style="background-image: url('img/bg-dm1.jpg')"
              ></div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/bg-dm2.jpg')"
              ></div>
              <h3>Šta direktna demokratija znači u stvarnosti?</h3>
              <p>
                U demokratiji moć leži na narodu. Zbog toga ljudi moraju imati
                pravo donošenja odluka na lokalnom, regionalnom, nacionalnom i
                transnacionalnom nivou. Cilj nam je uspostavljanje obavezujućih
                građanskih inicijativa i obaveznih referenduma. O čemu se radi u
                ovim instrumentima direktne demokratije?
              </p>
              <p>
                Podržana propisanom količinom potpisa, građanska inicijativa je
                peticija kojom se traži referendum o zakonskom predlogu koji su
                izradili građani. Stvarni referendum je drugi korak. Na
                obaveznom referendumu određena pitanja moraju biti stavljena
                pred građane na odobrenje (na primer, izmene ustava ili članstvo
                u međunarodnoj organizaciji).
              </p>
              <p>
                Direktna demokratija mora slijediti poštena i transparentna
                pravila i ne smije biti ograničena kvorumom ili bilo kojim
                drugim preprekama.
              </p>
              <p>
                Naš rad je usklađen sa „Kodeksom dobre prakse na referendumima“
                koji je objavila Venecijanska komisija, a koji utvrđuje poštene
                kriterijume za direktnu demokratiju. Venecijansku komisiju je
                osnovao Savjet Evrope da bi savjetovala države o ustavnim
                pitanjima i Kodeks se oslanja na evropsko izborno naslijeđe
                opšteg, jednakog, slobodnog i tajnog biračkog prava.
              </p>

              <p>
                <a
                  href="https://www.venice.coe.int/webforms/events/"
                  target="_blank"
                  >Venecijanska komisija</a
                >
                definiše uslove za sprovođenje ovih principa, plus posebna
                pravila koja treba da regulišu sprovođenje referenduma,
                uključujući „poštovanje osnovnih prava“, „posmatranje
                referenduma“ i postojanje efikasnog žalbenog sistema“. Više
                informacija možete pronaći u <a href="http://www.e-democracy.md/files/elections/venice-code-good-practice-referendums-17-03-2007-en.pdf" target="_blank">„Kodeksu dobre prakse o
                referendumima“</a>.
              </p>

              <p>
                Profesor Matt Kvortrup sa Univerziteta u Coventriju objavio je
                Bibliografiju o direktnoj demokratiji i referendumima, možete je
                preuzeti
                <a
                  href="https://www.democracy-international.org/sites/default/files/literature_on_direct_democracy.pdf"
                  target="_blank"
                  >ovdje</a
                >.
              </p>

              <p>
                Više informacija: „Šta je narodna ili građanska inicijativa?“,
                Film Michele Andina,
                <a href="https://www.swissinfo.ch/eng" target="_blank"
                  >svissinfo.ch</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Here is our team</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/avatar.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Romina Hadid</h4>
                <p class="category text-primary">Model</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/ryan.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Ryan Tompson</h4>
                <p class="category text-primary">Designer</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/eva.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Eva Jenner</h4>
                <p class="category text-primary">Fashion</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-google-plus"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-youtube"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Želite da radimo zajedno?</h2>
        <p class="description">Vaše mišljenje nam je veoma važno.</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="Name..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Email Here..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Type a message..."
              ></textarea>
            </div>
            <div class="send-button">
              <n-button type="primary" round block size="lg"
                >Pošalji poruku</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "global",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style></style>
