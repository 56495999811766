<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg-project.jpg')"
      >
      </parallax>
      <div class="content-center">
        <img
          class="n-logo"
          style="max-height: 150px"
          src="img/now-logo.png"
          alt=""
        />

        <div class="container">
          <h1 class="title">Lokalni projekti</h1>
          <div class="text-center">
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-google-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Glas doktora NKB</h2>
            <h5 class="description">
              Da li smatrate da treba formirati Koordinacioni COVID centar u Nikšiću?
            </h5>
            <p>
              Naime, smatramo veoma potrebnim da Opšta Bolnica Nikšić, koja je
              regionalni kovid centar ima bolje koordinisan rad sa Specijalnom
              bolnicom u Brezoviku. Ukoliko postoje bilo kakvi lični animoziteti i
              nesaradanja između osoblja, ti problemi se moraju
              prevazići. To bi se moglo riješiti upućivanjem inicijative
              Ministarstvu zdravlja, kojom bi se tražilo:
            </p>
            <p>1. Stvaranje Koordinacionog centra za ove dvije bolnice.</p>
            <p>
              2. Koordinacioni centar bi rukovodio rasporedom i organizacijom
              liječenja covid pacijenata u obije bolnice.
            </p>
            <p>
              3. Koordinacioni centar bi primao izvještaje iz obije bolnice o
              broju oboljelih, životno ugroženih pacijenata, smještajnim
              kapacitetima, zauzetosti kreveta, prosječnom broju dana liječenja
              pacijenta i svom drugom statistikom neophodnom za donošenje
              adekvatnih odluka.
            </p>
            <p>
              4. Koordinacioni centar bi organizovao ... neophodnih za liječenje
              ove specifične bolesti
            </p>
            <p>
              5. Predsjednik Koordinacionog centra bi bio infektolog, zamjenik
              bi bio pulmolog. Na ovaj način bi se Covid centar Opšte bolnice u
              Nikšiću organizovao u jednu cjelinu sa Specijalnom bolnicom
              Brezovik, čime bi liječenje bilo efikasnije, a pritisak na
              zaposlene manji.
            </p>
            <h5 class="description">
              Da li se slažete da prema Ministarstvu zdravlja uputimo ovakvu
              inicijativu i objavimo je u medijima?
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-12">
              <div
                class="image-container"
                style="background-image: url('img/gd_1.png')"
              >
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div
                class="image-container"
                style="background-image: url('img/gd_2.png')"
              >
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Here is our team</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/avatar.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Romina Hadid</h4>
                <p class="category text-primary">Model</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/ryan.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Ryan Tompson</h4>
                <p class="category text-primary">Designer</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/eva.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Eva Jenner</h4>
                <p class="category text-primary">Fashion</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-google-plus"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-youtube"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Want to work with us?</h2>
        <p class="description">Your project is very important to us.</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="First Name..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Email Here..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Type a message..."
              ></textarea>
            </div>
            <div class="send-button">
              <n-button type="primary" round block size="lg"
                >Send Message</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "local",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style></style>
